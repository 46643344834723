import ArticleViewer from "containers/article/ArticleViewer"

const ArticlePage = () => {
    
    return(
        <>
            <ArticleViewer />
            {/* <ArticlePreviewer /> */}
        </>
    )
}

export default ArticlePage;