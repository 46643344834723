const duration = {
    shortest: '150ms',
    shorter: '200ms',
    short: '250ms',
    standard: '300ms',
    long:'400ms',
    longer:'500ms',
    longest:'700ms',
    enteringScreen: '225ms',
    leavingScreen: '195ms'
}

const transition = {
    duration
}


export default transition;